import "./Sell.scss";
function Sell() {
  return (
    <div className={"Sell"}>
      <button className={"sellButton"}>
          Vendre un article
      </button>
    </div>
  );
}

export default Sell;